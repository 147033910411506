@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* colours */
  --bg: #f4f3ee;
  --accent: #E0AFA0;
  --black: #333437;

  /* typography */
  font-family: 'Quicksand';
  color: var(--black);
  --fs-large: clamp(1.5rem, calc(3vw + 1rem), 5rem);
  --fs-medium: clamp(1.1rem, calc(2vw + 0.75rem), 3.5rem);
  --fs-small: clamp(1.5rem, calc(1.5vw + 0.5rem), 2.2rem);
  --fs-extra-small: clamp(0.75rem, calc(1vw + 0.75rem), 1.25rem);
  font-size: var(--fs-extra-small);
  font-weight: 400;

  text-align: center;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
